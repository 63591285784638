import React, { ReactNode, useMemo, Fragment } from 'react';
import { Link } from 'gatsby';

interface Props {
  to: string;
  children: ReactNode;
  className?: string;
  onClick?: (targetLink: string) => void;
}
const RouteLink = ({ to, children, onClick, ...rest }: Props) => {
  const isExternal = useMemo(() => {
    return ['https://', 'http://', 'mailto:'].some(flag => to.startsWith(flag));
  }, [to]);

  const handleClick = (to: string) => () => {
    onClick?.(to);
  };

  return (
    <Fragment>
      {isExternal ? (
        <a href={to} target="_blank" rel="noopener noreferrer" {...rest} onClick={handleClick(to)}>
          {children}
        </a>
      ) : (
        <Link to={to} {...rest} onClick={handleClick(to)}>
          {children}
        </Link>
      )}
    </Fragment>
  );
};

export default RouteLink;
