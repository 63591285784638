import React from 'react';
import { Box } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ padding: '1rem', textAlign: 'right', fontSize: '0.8rem' }}>
      © 2020, Built with Lubycon
    </Box>
  );
};

export default Footer;
