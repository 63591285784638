import { Menu } from 'src/models';

export const BlogMenu: Menu = {
  name: 'BLOG',
  link: 'https://lubycon.medium.com/',
};
export const MentoringMenu: Menu = {
  name: 'SANDBOX',
  link: '/mentoring/intro',
};
export const ContactMenu: Menu = {
  name: 'CONTACT',
  link: 'mailto:help@lubycon.io',
  external: true,
};

export const GlobalMenus: Menu[] = [BlogMenu, ContactMenu];
