import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SEO from 'components/Utils/SEO';

interface Props {
  children: ReactNode;
  title?: string;
  showTitle?: boolean;
  description?: string;
  hideFooter?: boolean;
}
const Page = ({ children, title, description, hideFooter = false }: Props) => {
  return (
    <div data-layout="page">
      <SEO title={title} description={description} />
      <Header />
      <Box sx={{ backgroundColor: 'common.white' }}>{children}</Box>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Page;
