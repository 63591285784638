import React from 'react';
import { Link } from 'gatsby';
import { Grid, useMediaQuery, useTheme, Box } from '@mui/material';
import { GlobalMenus } from 'constants/menu';
import RouteLink from 'components/Utils/RouteLink';
import { getLogoSrc } from 'utils/logo';
import { logger } from '@lubycon/logger';

const headerLogger = logger.getPageLogger('header_global_nav');

const Header = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const logoSrc = getLogoSrc(matches ? 'symbol' : 'logo');

  return (
    <Box
      component="header"
      sx={{
        position: 'fixed',
        width: '100%',
        padding: 1,
        top: 0,
        left: 0,
        zIndex: 10,
        backdropFilter: 'blur(4px)',
      }}
    >
      <Grid justifyContent="space-between" alignItems="center" container>
        <Grid xs="auto" item>
          <Link to="/" onClick={() => headerLogger.click('logo')}>
            <Box component="img" src={logoSrc} alt="루비콘 로고" sx={{ width: { md: 130, xs: 40 } }} />
          </Link>
        </Grid>
        <Grid xs="auto" item>
          <Box component="ul" sx={{ display: 'flex', padding: 0, margin: 0, alignItems: 'center', height: '100%' }}>
            {GlobalMenus.map((menu, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  fontSize: {
                    md: '1rem',
                    xs: '0.8rem',
                  },
                  listStyle: 'none',
                  marginRight: {
                    md: 2,
                    xs: 1,
                  },
                }}
              >
                <Box
                  component={RouteLink}
                  sx={{
                    color: 'text.secondary',
                    textDecoration: 'none',
                    transition: 'color 0.2s ease-in',
                    '&:hover': {
                      color: 'primary.main',
                    },
                    fontSize: {
                      md: '1rem',
                      xs: '0.7rem',
                    },
                  }}
                  to={menu.link}
                  onClick={() => headerLogger.click('header_menu', { name: menu.name, link: menu.link })}
                >
                  {menu.name}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
